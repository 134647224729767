import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Koroška is one of Slovenia's most mountainous regions. It is characterised
by the whiteness of the rocks and the soft slopes of the Alpine foothills,
which along with the green of the forests give it its characteristic
appearance.`}</p>
    <br />
    <p>{`The forests of Koroška are deep and dark and they are
criss-crossed by cart tracks and forest paths. Many trails follow ridges,
with the best views into the valleys.`}</p>
    <br />
    <p>{`You will not find great crowds of bikers
here, and therefore your experience of unspoilt nature will be all the
more genuine.`}</p>
    <TrailguidePlugin content="lat=46.5386&lng=14.8721" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=11,46.5386,14.8721"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Jamnica`}</h4>
    <p>{`The Koroška region is in the northern part of Slovenia, at the boarder to
Austria. Mellow hills rise from the plains of Kärnten. In this beautiful
hills, there is the Jamnica Single Trail Park.
Find the trails `}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=15,46.5965,14.8786&map=cyclemap"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/koroska_13.jpg" mdxType="Image" />
    <br />
    <p>{`Set in forests and pastures with magnificent views. More than 20 km of
maintained trails offer gentle flow salted with some more technical sections
in an awesome forest. The trails are linked to each other by a nice uphill
trail. The possibilities of combining them is endless and you can always stop
at Dixis Farm for some refreshments or to fix your bike.`}</p>
    <Image src="destinations/koroska_14.jpg" mdxType="Image" />
    <br />
    <p>{`Due to its sunny location, the Jamnica Park season begins in April and
finishes with the first snow in November. The park also offers guided
tours and provides bike rental. Find more information about the Trail
Park `}<a parentName="p" {...{
        "href": "https://www.bikenomad.com/en/main/singletrailparkjamnica"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/koroska_12.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=46.5956&lng=14.8786&coverage=5" mdxType="TrailguidePlugin" />
    <h4>{`Open the trails of the Trail Park Jamnica directly in the `}<a parentName="h4" {...{
        "href": "https://trailguide.net/?pos=14,46.5984,14.8853&map=cyclemap"
      }}>{`web-app`}</a>{`.`}</h4>
    <br />
    <h4>{`Higher mountains`}</h4>
    <p>{`From Jamnica we go further south, the mountains get higher and the valleys
deeper. The climbs get longer but also the descents. We find exciting trails
that follow long and scenic ridges down into the valleys.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1702"
      }}>{`here`}</a></p>
    <Image src="destinations/koroskatg_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Ridges`}</h4>
    <p>{`The landscape is wild and the Slovenian mountains is one of the few places
in the Alps where traces of humans are less visible.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1702"
      }}>{`here`}</a></p>
    <Image src="destinations/koroskatg_2.jpg" mdxType="Image" />
    <br />
    <h4>{`EWS`}</h4>
    <p>{`Nevertheless, the trails are well accessible. Since the Enduro World
Series in 2018, the region is known world wide. Many pictures of flowy
brown lines, cutting through the green of the forest floor went through
social media.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/5594"
      }}>{`here`}</a></p>
    <Image src="destinations/koroska_16.jpg" mdxType="Image" />
    <br />
    <p>{`The race included trails of the Single Trail Park Jamnica, some gems
hidden in the deep forests around Črna na Koroškem. And the long descents
from mount Petzen, which are accessible by lift. Here you also find one
of the longest flow trails of the world:`}</p>
    <Image src="destinations/koroska_15.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=46.5275&lng=14.7779&coverage=3" mdxType="TrailguidePlugin" />
    <h4>{`Open the trails of mount Petzen directly in the `}<a parentName="h4" {...{
        "href": "https://trailguide.net/?pos=14,46.5297,14.7812&map=cyclemap"
      }}>{`web-app`}</a>{`.`}</h4>
    <br />
    <h4>{`Black Hole Trail`}</h4>
    <p>{`The Black Hole Trail is a special challenge, even for the most skilled
mountain bikers. It runs through abandoned tunnels of the Mežica mine.
From the day it closed down to the day they started setting up the trail,
no human foot stepped into these tunnels. The vast halls were once filled
with tons and tons of lead ore. More about the trail
`}<a parentName="p" {...{
        "href": "https://www.podzemljepece.com/en/main/black-hole-trail"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/koroska_18.jpg" mdxType="Image" />
    <br />
    <p>{`Over five horizons it descents for about 150 metres. Now and then you
will encounter very interesting sections, where you can relax for a moment
and soak in the endless blackness of the great halls. Of course, if any
section is too difficult, you can always conquer it on foot.`}</p>
    <Image src="destinations/koroska_19.jpg" mdxType="Image" />
    <br />
    <h4>{`Bike Park Poseka`}</h4>
    <p>{`But lets get back to the surface, to the small bike park of Poseka. It is
just outside of Ravne na Koroškem with two different routes
available – Šnel Pepi Flow and Enduro Bavči. Both are short but fun and on
the easier side.
Find the trails `}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=16,46.5382,14.9548&map=cyclemap"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/koroska_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Bike Park Kope`}</h4>
    <p>{`A bit further east and higher in the mountains is the Bike Park Kope. There
are three trail, inlcuding a red one and the descents are longer. Who likes
to jump will find good oportunities here.
Find the trails `}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=15,46.5055,15.2058&map=cyclemap"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/koroska_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Tour biking`}</h4>
    <p>{`Besides the wild nature and beautiful single trails, you can find many
possibilities for tour biking. E-bike destination Ribnica na Pohorju,
stages of Drava River Cycling Route and Slovenian MTB long distance path
are recommender by locals. Find this and more tour biking here:`}</p>
    <Image src="destinations/koroska_10.jpg" mdxType="Image" />
    <TrailguidePlugin content="/x3b" mdxType="TrailguidePlugin" />
    <h4>{`Open the tour biking routes directly in the `}<a parentName="h4" {...{
        "href": "https://trailguide.net#3b"
      }}>{`web-app`}</a>{`.`}</h4>
    <br />
    <h4>{`Drava River Cycling`}</h4>
    <p>{`Beautiful cycling roads along the Drava River. The route is considered to be
one of the most beautiful bike trails in Europe. The trail leads from river’s
spring in Italy, through Austrian Carinthia, to Slovenia and further on to
Croatia where it ends at Legrad.`}</p>
    <br />
    <p>{`The “Koroska@digital” project is
financed by the European Union from the European Regional Development Fund,
the Republic of Slovenia and the municipalities of the Koroška region.`}</p>
    <Image src="destinations/koroska_8.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      